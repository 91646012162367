<template>
  <el-result icon="success" title="客户登记" sub-title="客户登记成功！"> </el-result>
</template>
<script>
import { ElResult } from "element-plus";
export default {
  components: {
    [ElResult.name]: ElResult,
  },
};
</script>
